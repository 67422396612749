<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '950px' }"
    header="DETALLE PRODUCTOS"
    :modal="true"
    :maximizable="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span
            ><strong>C&Oacute;DIGO INTERNO:</strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            placeholder="Código Origen"
            v-model="data_producto.codigo_interno"
          ></InputText>
          <small class="p-invalid" v-if="errors.codigo_interno">{{
            errors.codigo_interno[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>C&Oacute;DIGO ORIGEN:</strong></span>
          <InputText
            placeholder="Código Origen"
            v-model="data_producto.codigo_origen"
          ></InputText>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>C&Oacute;DIGO EQUIVALENTE:</strong></span>
          <InputText
            placeholder="Código Origen"
            v-model="data_producto.codigo_equivalente"
          ></InputText>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>C&Oacute;DIGO DE BARRAS:</strong></span>
          <InputText
            placeholder="Código de barras/Código Interno"
            v-model="data_producto.codigo_barra"
          />
          <small class="p-invalid" v-if="errors.codigo_barra">{{
            errors.codigo_barra[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <span
            ><strong>NOMBRE DE PRODUCTO | DESCRIPCI&Oacute;N:</strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            placeholder="Descripción/Nombre del Producto"
            autofocus
            v-model="data_producto.descripcion"
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>DIMENSIONES:</strong></span>
          <InputText placeholder="Medidas" v-model="data_producto.medidas" />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>GRUPO: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="grupoSelect"
              :options="grupos"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un grupo"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.nombre }} </span>
                </div>
              </template>
            </Dropdown>
            <!-- v-if="'Grupo Crear' in auth.user.permissions" -->
            <Button
              class="p-button-info"
              icon="pi pi-plus"
              @click="activarGrupoModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.grupo_id">{{
            errors.grupo_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>MARCA: </strong> <span class="p-invalid">*</span></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="laboratorioSelect"
              :options="laboratorios"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un marca"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span
                    >{{ slotProps.option.nombre }} -
                    {{ slotProps.option.nombre_proveedor }}
                  </span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button-info"
              v-if="'Laboratorio Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarLaboratorioModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.fabrica_id">{{
            errors.fabrica_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>PROCEDENCIA: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="procedenciaSelect"
              :options="procedencias"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione una procedencia"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.nombre }} </span>
                </div>
              </template>
            </Dropdown>
            <!-- v-if="'Grupo Crear' in auth.user.permissions" -->
            <Button
              class="p-button-info"
              icon="pi pi-plus"
              @click="activarProcedenciaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.procedencia">{{
            errors.procedencia[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>UNIDAD DE MEDIDA: </strong>
            <span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="unidadSelect"
              :options="unidadesmedidas"
              :filter="true"
              optionLabel="nombre"
              placeholder="Seleccione una unidade medida"
            >
              <template #option="slotProps">
                <span> {{ slotProps.option.nombre }} </span>
              </template>
            </Dropdown>
            <Button
              class="p-button-info"
              v-if="'Unidad de Medida Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarUnidadMedidaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.unidadmedida_id">{{
            errors.unidadmedida_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Iacute;N: </strong></span>
          <InputNumber
            :inputClass="'text-right'"
            v-model="data_producto.stock_minimo"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.stock_minimo">{{
            errors.stock_minimo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-1">
          <span><strong>S. M&Aacute;X: </strong></span>
          <InputNumber
            v-model="data_producto.stock_maximo"
            placeholder="0"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.stock_maximo">{{
            errors.stock_maximo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2 fondo mb-1">
          <span><strong>STOCK ACTUAL: </strong></span>
          <InputNumber
            v-model="data_producto.stock_actual"
            showButtons
            placeholder="0"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
            :disabled="!actualizar_precio_compra"
          />
        </div>
        <div class="field col-12 md:col-3 fondo mb-1">
          <span><strong>PRECIO COMPRA: </strong></span>
          <div class="p-inputgroup">
            <InputNumber
              placeholder="0.00"
              v-model="data_producto.precio_compra"
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="de-ED"
              :disabled="!actualizar_precio_compra"
            />
            <Button
              v-if="data_producto.id != null"
              class="p-button-info"
              icon="pi pi-refresh"
              v-tooltip.top="'Actualizar precio de compra y stock del producto'"
              @click="actualizar_precio_compra = !actualizar_precio_compra"
            />
          </div>
          <small class="p-invalid" v-if="errors.precio_compra">{{
            errors.precio_compra[0]
          }}</small>
        </div>
        <!-- <div
          class="field col-12 md:col-1 fondo mb-1"
          v-if="data_producto.id != null"
        >
          <span><strong>ACTUALIZAR</strong></span>
          <Checkbox
            class="text-center mt-2 ml-2 mr-2"
            v-model="actualizar_precio_compra"
            :binary="true"
            v-tooltip.top="'Actualizar precio de compra y stock del producto'"
          >
          </Checkbox>
          <i
            class="pi pi-refresh mr-2 mb-1"
            style="font-size: 1em; font-weight: bold"
          ></i>
        </div> -->
        <div class="field col-12 md:col-2 fondo mb-1">
          <span><strong>PRECIO VENTA: </strong></span>
          <InputNumber
            placeholder="0.00"
            v-model="data_producto.precio_sugerido"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.precio_sugerido">{{
            errors.precio_sugerido[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_producto.estado"
            :disabled="!data_producto.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>CASO DE USO: </strong></span>
          <Textarea
            v-model="data_producto.caso_uso"
            rows="1"
            autoResize
            placeholder="Ingrese el caso de uso del Producto"
          />
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>UBICACI&Oacute;N: </strong></span>
          <Textarea
            v-model="data_producto.ubicacion"
            rows="1"
            autoResize
            placeholder="Ingrese la ubicaci&oacute;n del Producto"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span v-if="data_producto.facturado == 1" for="facturado" class="mr-2"
            ><strong>HOMOLOGADO: <i class="pi pi-check"></i></strong
          ></span>
          <span v-if="data_producto.facturado != 1" class="mr-2"
            ><strong>HOMOLOGAR: </strong></span
          >
          <Checkbox
            v-model="facturar_producto"
            :binary="true"
            v-tooltip.top="
              data_producto.facturado == 1
                ? 'Producto Homologado'
                : 'Homologar Producto'
            "
            :disabled="data_producto.facturado == 1"
          />
        </div>
        <div class="field col-12 md:col-10">
          <Dropdown
            class="ml-2"
            v-model="codigoSINSelect"
            :options="codigossin"
            :filter="true"
            optionLabel="descripcionProducto"
            placeholder="Seleccione un codigo siat"
          >
            <template #option="slotProps">
              <span
                class="font-weight-bold"
                style="font-size: 1em"
                v-tooltip.top="slotProps.option.descripcionProducto"
              >
                {{
                  slotProps.option.descripcionProducto.length > 100
                    ? slotProps.option.descripcionProducto.substring(0, 100) +
                      "..."
                    : slotProps.option.descripcionProducto
                }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <!--  :disabled="!enviado ? false : true" -->
      <Button
        :label="data_producto.id == null ? 'GUARDAR' : 'ACTUALIZAR'"
        :icon="data_producto.id == null ? 'pi pi-save' : 'pi pi-refresh'"
        class="p-button-info p-button-lg"
        @click="guardarProducto"
      />
      <!--  :disabled="!enviado ? false : true"
        :loading="enviado" -->
    </template>
  </Dialog>
  <UnidadMedidaCreate
    :show="UnidadMedidaModal"
    :unidadmedida="unidadMedida"
    @closeModal="cerrarModalUnidadMedida"
    @actualizarListado="cargarUnidadesMedidas"
  ></UnidadMedidaCreate>
  <LaboratorioCreate
    :mostrar="LaboratorioModal"
    :laboratorio="laboratorio"
    @closeModal="cerrarModalLaboratorio"
    @actualizarListadoLaboratorio="cargarLaboratorios"
  ></LaboratorioCreate>
  <GrupoCreate
    :show="GrupoModal"
    :grupo="grupo"
    @closeModal="cerrarModalGrupo"
    @actualizarListado="cargarGrupos"
  >
  </GrupoCreate>
  <ProcedenciaCreate
    :show="ProcedenciaModal"
    :procedencia="procedencia"
    @closeModal="cerrarModalProcedencia"
    @actualizarListado="cargarProcedencias"
  >
  </ProcedenciaCreate>
</template>

<script>
import UnidadMedidaCreate from "@/module/unidadmedida/UnidadMedidaCreate.vue";
import LaboratorioCreate from "@/module/laboratorios/LaboratorioCreate.vue";
import ProductService from "@/service/ProductService";
import LaboratorioService from "@/service/LaboratorioService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import { useAuth } from "@/stores";
import GrupoService from "@/service/GrupoService";
import ProcedenciaService from "@/service/ProcedenciaService";
import GrupoCreate from "@/module/grupos/GrupoCreate.vue";
import ProcedenciaCreate from "@/module/procedencias/ProcedenciaCreate.vue";

export default {
  components: {
    UnidadMedidaCreate,
    LaboratorioCreate,
    GrupoCreate,
    ProcedenciaCreate,
  },
  emits: ["closeModal", "actualizarListadoProductos"],
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  productoService: null,
  laboratorioService: null,
  unidadmedidaService: null,
  grupoService: null,
  procedenciaService: null,
  auth: null,
  data() {
    return {
      activarModal: this.mostrar,
      data_producto: this.producto,
      laboratorios: {},
      unidadesmedidas: {},
      laboratorioSelect: {},
      unidadSelect: {},
      UnidadMedidaModal: false,
      LaboratorioModal: false,
      laboratorio: { estado: { label: "Activo", value: 1 } },
      unidadMedida: { estado: { label: "Activo", value: 1 } },
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      stock_minimo: 0,
      stock_maximo: 0,
      precio_sugerido: 0,
      precio_almacen: 0,
      codigo_interno: 0,
      grupos: {},
      grupo: { estado: { label: "Activo", value: 1 } },
      grupoSelect: {},
      procedencias: {},
      procedencia: { estado: { label: "Activo", value: 1 } },
      procedenciaSelect: {},
      GrupoModal: false,
      ProcedenciaModal: false,
      actualizar_precio_compra: 0,
      facturar_producto: 0,
      codigoSINSelect: { label: "Seleccione un codigo siat", value: null },
      unidadMedidaSelect: {},
      codigossin: [],
    };
  },
  created() {
    this.auth = useAuth();
    this.productoService = new ProductService();
    this.laboratorioService = new LaboratorioService();
    this.unidadmedidaService = new UnidadmedidaService();
    this.grupoService = new GrupoService();
    this.procedenciaService = new ProcedenciaService();
  },
  mounted() {
    /* this.cargarLaboratorios();
    this.cargarUnidadesMedidas();
    this.cargarGrupos();
    this.cargarProcedencias(); */
    //this.cargarCodigosSIN();
    this.getDatosNecesariosProducto();
  },
  methods: {
    getDatosNecesariosProducto() {
      this.productoService.getDatosNecesariosProducto().then((response) => {
        this.laboratorios = response.datos.fabricas ?? [];
        this.unidadesmedidas = response.datos.unidadmedida ?? [];
        this.grupos = response.datos.grupos ?? [];
        this.procedencias = response.datos.procedencias ?? [];
        this.codigossin = response.datos.codigossin ?? [];
      });
    },

    cargarGrupos(grupo = null) {
      this.grupoService
        .getGrupoAll()
        .then((response) => {
          this.grupos = response.grupos;
          if (grupo) {
            this.grupos.forEach((element) => {
              if (element.id == grupo.id) {
                this.grupoSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarCodigosSIN() {
      this.productoService
        .getCodigoSIN()
        .then((response) => {
          this.codigossin = response.codigossin;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarProcedencias(procedencia = null) {
      this.procedenciaService
        .getProcedenciaAll()
        .then((response) => {
          this.procedencias = response.procedencias;
          if (procedencia) {
            this.procedencias.forEach((element) => {
              if (element.id == procedencia.id) {
                this.procedenciaSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activarGrupoModal() {
      this.GrupoModal = true;
      this.grupo = {
        estado: { label: "ACTIVO", value: 1 },
      };
    },
    cerrarModalGrupo() {
      this.GrupoModal = false;
      this.grupo = {};
    },
    activarProcedenciaModal() {
      this.ProcedenciaModal = true;
      this.procedencia = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalProcedencia() {
      this.ProcedenciaModal = false;
      this.procedencia = {};
    },
    cerrarModalLaboratorio() {
      this.LaboratorioModal = false;
      this.laboratorio = {};
    },
    cerrarModalUnidadMedida() {
      this.UnidadMedidaModal = false;
      this.unidadMedida = {};
    },
    activarLaboratorioModal() {
      this.LaboratorioModal = true;
      this.laboratorio = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarUnidadMedidaModal() {
      this.UnidadMedidaModal = true;
      this.unidadMedida = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cargarLaboratorios(fabrica = null) {
      this.laboratorioService
        .getAllLaboratorios()
        .then((response) => {
          this.laboratorios = response.fabricas;
          if (fabrica) {
            this.laboratorios.forEach((element) => {
              if (element.id == fabrica.id) {
                this.laboratorioSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUnidadesMedidas(unidadmedida = null) {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((response) => {
          this.unidadesmedidas = response;
          if (unidadmedida) {
            this.unidadesmedidas.forEach((element) => {
              if (element.id == unidadmedida.id) {
                this.unidadSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModal() {
      this.errors = {};
      this.laboratorioSelect = {};
      this.unidadSelect = {};
      this.grupoSelect = {};
      this.procedenciaSelect = {};
      this.activarModal = false;
      this.$emit("closeModal");
      this.actualizar_precio_compra = 0;
      this.facturar_producto = 0;
      this.codigoSINSelect = {};
      this.unidadMedidaSelect = {};
    },
    guardarProducto() {
      if (
        this.facturar_producto == 1 &&
        this.data_producto.codigo_interno == null
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar un codigo interno para el producto",
          life: 3000,
        });
        return;
      }
      this.errors = {};
      this.enviado = true;
      if (this.data_producto.id) {
        //Actualizar
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          grupo_id: this.grupoSelect.id,
          procedencia_id: this.procedenciaSelect.id,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          /* facturar_producto: this.facturar_producto ? 1 : 0, */
          facturar_producto: this.data_producto.facturado
            ? 1
            : this.facturar_producto
            ? 1
            : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
        };

        this.productoService.actualizarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
          } else {
            this.errors = {};
            if (response.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: response.mensaje,
                life: 3000,
              });
              return;
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Actualizado!",
                detail: response.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListadoProductos");
              this.$emit("closeModal");
            }
          }
          this.enviado = false;
        });
      } else {
        //Registrar nuevo producto
        if (this.laboratorioSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar un laboratorio",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        if (this.unidadSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar una unidad de medida",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          grupo_id: this.grupoSelect.id,
          procedencia_id: this.procedenciaSelect.id,
          facturar_producto: this.facturar_producto ? 1 : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
        };

        this.productoService.registrarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
          } else {
            this.errors = {};
            if (response.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: response.mensaje,
                life: 3000,
              });
              return;
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: response.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListadoProductos");
              this.$emit("closeModal");
            }
          }
          this.enviado = false;
        });
      }
    },
    findIndex(id) {
      let index = -1;
      for (let i = 0; i < this.productos.length; i++) {
        if (this.productos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
  watch: {
    mostrar(newVal) {
      this.activarModal = newVal;
    },
    producto(newVal) {
      this.data_producto = newVal;

      // cuando es nuevo producto que cargue el primer elemento de cada lista que tenga el id 1
      if (this.data_producto.id == null) {
        this.grupoSelect = this.grupos.find((grupo) => grupo.id == 1);
        this.procedenciaSelect = this.procedencias.find(
          (procedencia) => procedencia.id == 1
        );
        this.laboratorioSelect = this.laboratorios.find(
          (laboratorio) => laboratorio.id == 1
        );
        this.unidadSelect = this.unidadesmedidas.find(
          (unidad) => unidad.id == 1
        );
        return;
      }
      this.grupoSelect = this.grupos.find(
        (grupo) => grupo.id == this.data_producto.grupo_id
      );
      this.procedenciaSelect = this.procedencias.find(
        (procedencia) => procedencia.id == this.data_producto.procedencia_id
      );
      this.laboratorioSelect = this.laboratorios.find(
        (laboratorio) => laboratorio.id == this.data_producto.fabrica_id
      );
      this.unidadSelect = this.unidadesmedidas.find(
        (unidad) => unidad.id == this.data_producto.unidadmedida_id
      );
      this.codigoSINSelect = this.codigossin.find(
        (codigo) => codigo.codigoProducto == this.data_producto.codigo_siat
      );
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
  //border: 1px solid #002236;
}
</style>
